// 游伴
const url = {
    dev: {
        publicUrl: 'http://car.jiananchong.cn/',
        manageUrl: 'http://car.jiananchong.cn/',
    },
    pro: {
        publicUrl: 'http://car.jiananchong.cn/',
        manageUrl: 'http://car.jiananchong.cn/',
    }
}
// 樂電
// const url = {
//     dev: {
//         publicUrl: 'http://www.ledianxinnengyuan.com/',
//         manageUrl: 'http://www.ledianxinnengyuan.com/',
//     },
//     pro: {
//         publicUrl: 'http://www.ledianxinnengyuan.com/',
//         manageUrl: 'http://www.ledianxinnengyuan.com/',
//     }
// }
export const get = () => {
    if(process.env.NODE_ENV === 'production'){
        return url['pro']
    }else{
        return url['dev']
    }
}
