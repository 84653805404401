import router from '@/router/index';
const state = {
    menuArr:[
        {
            path: '/',
            name: 'Home',
            name_cn:'主页',
            icon: 'el-icon-s-marketing',
            component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
        },
        {
            path: '/equipment',
            name: 'Equipment',
            name_cn:'设备管理',
            icon: 'el-icon-s-cooperation',
            component: {render: (e) => e("router-view")},
            children:[{
                path: '/equipment/equipmentList',
                name: 'EquipmentList',
                // @ts-ignore
                name_cn:'设备列表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/equipmentManagement/equipment/index.vue'),
            },{
                path: '/equipment/batteryEquipmentList',
                name: 'batteryEquipmentList',
                // @ts-ignore
                name_cn:'两轮电动车设备列表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/equipmentManagement/batteryEquipment/index.vue'),
            },{
                path: '/PowerConsumption/CumulativeElectricityInfo',
                name: 'CumulativeElectricityInfo',
                // @ts-ignore
                name_cn:'耗电统计',
                component: () => import(/* webpackChunkName: "about" */ '@/views/powerConsumption/CumulativeElectricityInfo.vue'),
            },{
                path: '/PowerConsumption/ElectricitySettlementInfo',
                name: 'ElectricitySettlementInfo',
                // @ts-ignore
                name_cn:'结算耗电记录',
                component: () => import(/* webpackChunkName: "about" */ '@/views/powerConsumption/ElectricitySettlementInfo.vue'),
            },{
                path: '/equipment/EquipmentFloorLock',
                name: 'EquipmentFloorLock',
                // @ts-ignore
                name_cn:'地锁列表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/equipmentManagement/equipmentFloorLock/index.vue'),
            }, ]
            // {
            //     path: '/equipment/electricMeterList',
            //     name: 'ElectricMeterList',
            //     // @ts-ignore
            //     name_cn:'电表列表',
            //     component: () => import(/* webpackChunkName: "about" */ '@/views/equipmentManagement/electricMeter/index.vue'),
            // }
        },
        {
            path: '/consumptionTemplate',
            name: 'ConsumptionTemplate',
            name_cn:'消费模板',
            icon: 'el-icon-s-platform',
            component: {render: (e) => e("router-view")},
            children:[{
                path: '/consumptionTemplate/batteryConsumptionTemplateList',
                name: 'batteryConsumptionTemplateList',
                // @ts-ignore
                name_cn:'两轮电动车模板列表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/batteryConsumptionTemplate/index.vue'),
            },{
                path: '/consumptionTemplate/carConsumptionTemplateList',
                name: 'carConsumptionTemplateList',
                // @ts-ignore
                name_cn:'四轮电动车模板列表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/consumptionTemplate/index.vue'),
            }]
        },
        {
          path: '/orderManagement',
          name: 'OrderManagement',
          name_cn:'订单管理',
          icon: 'el-icon-s-order',
          component: {render: (e) => e("router-view")},
          children:[{
              path: '/orderManagement/orderList',
              name: 'orderList',
              // @ts-ignore
              name_cn:'订单列表',
              component: () => import(/* webpackChunkName: "about" */ '@/views/orderManagement/index.vue'),
          },{
            path: '/orderManagement/batteryCarOrderList',
            name: 'batteryCarOrderList',
            // @ts-ignore
            name_cn:'两轮电动车订单列表',
            component: () => import(/* webpackChunkName: "about" */ '@/views/orderManagement/batteryCarOrder/index.vue'),
        }]
        }, 
        {
            path: '/withdrawal',
            name: 'Withdrawal',
            name_cn:'财务管理',
            icon: 'el-icon-s-data',
            component: {render: (e) => e("router-view")},
            children:[{
                path: '/withdrawal/account',
                name: 'WithdrawalAccount',
                // @ts-ignore
                name_cn:'提现账号',
                component: () => import(/* webpackChunkName: "about" */ '@/views/withdrawal/withdrawalNum/index.vue'),
            },{
                path: '/withdrawal/apply',
                name: 'WithdrawalApply',
                // @ts-ignore
                name_cn:'提现申请',
                component: () => import(/* webpackChunkName: "about" */ '@/views/withdrawal/withdrawalApply/index.vue'),
            },{
                path: '/topUp/list',
                name: '/topUpList',
                // @ts-ignore
                name_cn:'充值列表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/topUp/index.vue'),
              },]
        }, 
        {
            path: '/user',
            name: 'User',
            name_cn:'用户管理',
            icon: 'el-icon-s-custom',
            component: {render: (e) => e("router-view")},
            children:[{
                path: '/user/wechat',
                name: 'WeChat',
                // @ts-ignore
                name_cn:'微信用户',
                component: () => import(/* webpackChunkName: "about" */ '@/views/userManagement/wechat/index.vue'),
            },{
                path: '/user/card',
                name: 'Card',
                // @ts-ignore
                name_cn:'卡用户',
                component: () => import(/* webpackChunkName: "about" */ '@/views/userManagement/card/index.vue'),
            },{
                path:'/user/parkingLockUser',
                name: 'parkingLockUser',
                // @ts-ignore
                name_cn:'车位锁用户',
                component: () => import(/* webpackChunkName: "about" */ '@/views/userManagement/parkingLockUser/index.vue'),
            },{
                path: '/user/applyBills',
                name: 'ApplyBills',
                // @ts-ignore
                name_cn:'申请开票',
                component: () => import(/* webpackChunkName: "about" */ '@/views/userManagement/applyBills/index.vue'),
            }]
        },
        {
            path: '/operations',
            name: 'Operations',
            name_cn:'运营管理',
            icon: 'el-icon-s-management',
            component: {render: (e) => e("router-view")},
            children:[{
                path: '/operations/benefit',
                name: 'Benefit',
                // @ts-ignore
                name_cn:'分润明细',
                component: () => import(/* webpackChunkName: "about" */ '@/views/operationsManagement/benefit/index.vue'),
            },
            {
                path: '/operations/customerList',
                name: 'CustomerList',
                // @ts-ignore
                name_cn:'客服问答列表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/operationsManagement/customerService/index.vue'),
            },
            {
                path: '/operations/faultList',
                name: 'FaultList',
                // @ts-ignore
                name_cn:'用户维护上报',
                component: () => import(/* webpackChunkName: "about" */ '@/views/operationsManagement/fault/index.vue'),
            },
            {
                path: '/operations/parkingLockappointment',
                name: 'parkingLockappointment',
                // @ts-ignore
                name_cn:'车位预约记录',
                component: () => import(/* webpackChunkName: "about" */ '@/views/operationsManagement/parkingLockappointment/index.vue'),
            },
            {
                path: '/operations/dictionary',
                name: 'dictionary',
                // @ts-ignore
                name_cn:'数据字典',
                component: () => import(/* webpackChunkName: "about" */ '@/views/operationsManagement/dataDictionary/index.vue'),
            }
           ]
        },
        {
            path: '/community',
            name: 'Community',
            name_cn:'场所管理',
            icon: 'el-icon-s-home',
            component: {render: (e) => e("router-view")},
            children:[{
                path: '/community/list',
                name: 'communityList',
                // @ts-ignore
                name_cn:'场所列表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/communityManagement/index.vue'),
            }]
        },
        {
            path: '/rights',
            name: 'Rights',
            name_cn:'权限管理',
            icon: 'el-icon-lock',
            component: {render: (e) => e("router-view")},
            children:[{
                path: '/rights/menu',
                name: 'Menu',
                // @ts-ignore
                name_cn:'菜单管理',
                component: () => import(/* webpackChunkName: "about" */ '@/views/rights/menu/index.vue'),
              },{
                path: '/rights/user',
                name: 'rightsUser',
                // @ts-ignore
                name_cn:'用户管理',
                component: () => import(/* webpackChunkName: "about" */ '@/views/rights/user/index.vue'),
              },{
                path: '/rights/rightsManege',
                name: 'RightsManege',
                // @ts-ignore
                name_cn:'权限分配',
                component: () => import(/* webpackChunkName: "about" */ '@/views/rights/rightsManege/index.vue'),
              },{
                path: '/rights/roleManege',
                name: 'RoleManege',
                // @ts-ignore
                name_cn:'角色管理',
                component: () => import(/* webpackChunkName: "about" */ '@/views/rights/roles/index.vue'),
              }]
        }, 
        {
            path: '/report',
            name: 'Report',
            name_cn:'报表管理',
            icon: 'el-icon-s-data',
            component: {render: (e) => e("router-view")},
            children:[{
                path: '/report/benefit',
                name: 'reportBenefit',
                // @ts-ignore
                name_cn:'分润统计',
                component: () => import(/* webpackChunkName: "about" */ '@/views/reportManage/benefitStatistics/index.vue'),
              },{
                path: '/report/withdrawal',
                name: 'reportWithdrawal',
                // @ts-ignore
                name_cn:'提现统计',
                component: () => import(/* webpackChunkName: "about" */ '@/views/reportManage/withdrawalStatistics/index.vue'),
              },{
                path: '/report/order',
                name: 'OrderReport',
                // @ts-ignore
                name_cn:'订单统计报表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/reportManage/orderReport/index.vue'),
              },{
                path: '/report/customerData',
                name: 'CustomerData',
                // @ts-ignore
                name_cn:'客户数据报表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/reportManage/customerData/index.vue'),
              },{
                path: '/report/venueMmanagement',
                name: 'VenueManagement',
                // @ts-ignore
                name_cn:'场地经营报表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/reportManage/venueMmanagement.vue'),
              },{
                path: '/report/orderCostReport',
                name: 'OrderCostReport',
                // @ts-ignore
                name_cn:'场地成本回收进度报表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/reportManage/orderCostReport.vue'),
              },{
                path: '/report/batteryCarOrderYueReport',
                name: 'BatteryCarOrderYueReport',
                // @ts-ignore
                name_cn:'场地电瓶车经营报表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/reportManage/batteryCarOrderYueReport.vue'),
              },{
                path: '/report/orderDaySettlement',
                name: 'OrderDaySettlement',
                // @ts-ignore
                name_cn:'订单日结算记录报表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/reportManage/orderDaySettlement.vue'),
              },]
        }, 
        {
            path: '/message',
            name: 'Message',
            name_cn:'报文记录',
            icon: 'el-icon-s-finance',
            component: {render: (e) => e("router-view")},
            children:[{
                path: '/message/list',
                name: '/messageList',
                // @ts-ignore
                name_cn:'报文列表',
                component: () => import(/* webpackChunkName: "about" */ '@/views/message/index.vue'),
              },]
        },
    ],
}   

const mutations = {
    add: (state:any,view: any)=> {
        state.menuArr.push(view)
    },
    delete: (state: any, view: any)=> {
        let index = state.menuArr.indexOf(view)
        if (index > -1) {
            state.menuArr.splice(index, 1)
        }
    },
    set: (state: any, view: any)=> {
        state.menuArr = view
        let menu_list = []
        view.forEach((value,key)=>{
            let children = [];
            if(value['children'].length>0){
                value['children'].forEach((v,k)=>{
                    children.push({
                        path: v['path'],
                        name: v['name'],
                        name_cn:v['name_cn'],
                        icon: v['icon'],
                        component:() => import(/* webpackChunkName: "about" */ v['comp_path']),
                        children:[]
                    })
                })
            }                                                                                                                                                                                                                                                                                                                                   
            menu_list.push({
                path: value['path'],
                name: value['name'],
                name_cn:value['name_cn'],
                icon: value['icon'],
                component:() => import(/* webpackChunkName: "about" */ value['comp_path']),
                children:children
            })
        })
        router.options.routes = menu_list
    }
}

export default {
    namespaced: true,  // 这个是命名空间，用来区分不同的模块，我是根据文件的名字来区分
    state,
    mutations,
};
