import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Element from 'element-ui';
import './theme/element/index.css';
import axios from 'axios';
import echarts from 'echarts';
import '@/assets/iconfont/iconfont.css';
import AFTableColumn from 'af-table-column';

Vue.use(AFTableColumn);
Vue.use(Element);
Vue.use(Element);

Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.prototype.$bus = new Vue();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
