import Vue from 'vue'
import Vuex from 'vuex';
import VueRouter, { RouteConfig } from 'vue-router'
import Store from "@/store/index"
import {Watch} from "vue-property-decorator";

Vue.use(VueRouter)

// @ts-ignore
let menu_list = Store.state.menu.menuArr
// @ts-ignore
@Watch(Store.state.menu.menuArr)
function getMenu(oldValue,newValue){
}

const routes: Array<RouteConfig> = menu_list

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router;
