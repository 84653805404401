<template>
    <section class="orderContainer">
      <div class="search">
        <div class="inputs">
          <!-- 订单状态 -->
          <el-input class="wxName" v-model="searchInfo.data.OrderNum" placeholder="订单编号"></el-input>
          <el-input class="wxName" v-model="searchInfo.data.EquipmentNum" placeholder="设备编号"></el-input>
          <el-select class='select' v-model="state" clearable placeholder="订单状态" @change="selectStatus">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button icon="el-icon-search" circle @click="toSearch"></el-button>
          <el-button icon="el-icon-close" circle @click="backAll"></el-button>
          <el-button icon="el-icon-switch-button" circle @click="logOut"></el-button>
        </div>
      </div>
      <div class="container">
        <el-table
          :data="orderInfo"
          style="width: 100%"
          height="calc(100% - 65px)"
          class="table"
          fit
        >
        <!-- 数据展示区 -->
          <af-table-column
            label="充电用户"
            prop="WeChatName"
            min-width="100"
            align='center'>
            <template  slot-scope="scope">
             <p style="color:#40a9ff">{{scope.row.WeChatName }}</p>
             <p style="color:#40a9ff">{{scope.row.Phone }}</p>
            </template>
          </af-table-column>
          <af-table-column
            label="订单信息"
            prop="NeighbourhoodName"
            align='center'>
            <template slot-scope="scope">
                <p @click="checkDetail(scope.row.OrderNum)" style="cursor:pointer;color:#40a9ff">{{scope.row.OrderNum}}</p>
                <p>{{ scope.row.NeighbourhoodName }}</p>
                <p>{{ scope.row.EquipmentNum }}({{ scope.row.EquipmentPort }}号口)</p>
            </template>
          </af-table-column>
          <af-table-column
            label="支付金额(元)"
            prop="OrderAmount"
            align='center'>
          </af-table-column>
          <af-table-column
            label="耗电量"
            prop="OrderElectricNum"
            align='center'>
          </af-table-column>
          <af-table-column
            label="电费(元)"
            prop="ElectricityAmount"
            align='center'>
          </af-table-column>
          <af-table-column
            label="订单状态"
            prop="OrderState"
            align='center'>
            <template slot-scope="scope"><p :style='scope.row.statusStyle' class="orderStatus">{{scope.row.OrderState}}</p><p class="stopWay">{{scope.row.stopWay}}</p></template>
          </af-table-column>
          <af-table-column
            label="订单开始时间"
            prop="BeginTime"
            align='center'>
          </af-table-column>
          <af-table-column
            label="订单结束时间"
            prop="EndTime"
            align='center'>
          </af-table-column>
          <el-table-column label="操作" fixed="right" align='center' min-width="60">
            <template slot-scope="scope">
              <i class="el-icon-switch-button" @click="closeOrder(scope.$index, scope.row)" v-if="scope.row.OrderState=='已支付'"></i>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
        <el-drawer
          title=""
          :visible.sync="detailDrawer"
          :direction="direction"
          :before-close="handleClose"
          size="100%"
        >
          <detail v-if="detailDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></detail>
        </el-drawer>
        <!--分页-->
        <el-col :span="24" class="toolbar" >
          <el-pagination
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            @prev-click="prevpage"
            @next-click="nextpage"
            :page-size="10"
            :total="total"
            style="float:right;"
          ></el-pagination>
        </el-col>
      </div>
    </section>
  </template>
  
  <script>
  import {getOrder,exportOrder,CloseChargingOrder,getNeibourhood} from '@/api/public.js';
  import {baseUrl} from '@/api/url.js';
  import detail from './detail.vue';
  export default {
    data() {
      return {
        // 订单列表信息
        orderInfo:[],
        statusStyle:'', // 状态样式
        detailDrawer:false,
        id:'',
        row:'',
        direction:'rtl',
        // 分页样式
        total:0,
        searchInfo:{
          pageIndex:1,
          pageSize:10,
          data:{
            Process:-1,
            OrderState:-1,
            EquipmentNum:'',
            OrderNum:'',
            Address:'',
            NeighbourhoodId:-1,
          },
        },// 查询条件
        state:'',
        process:'',
        time:'',
        neighbourhoodId:'',
        statusOptions:[
          {value:0,label:'待支付'},
          {value:1,label:'已支付'},
          {value:2,label:'订单结算'},
          {value:3,label:'退款中'},
          {value:4,label:'部分退款'},
          {value:5,label:'全额退款'},
        ],
        ProcessOptions:[
          {value:0,label:'未开始'},
          {value:1,label:'充电中'},
          {value:2,label:'已完成'},
        ],
        payTypeOptions:[
          {value:0,label:'微信支付'},
          {value:3,label:'刷卡支付'},
        ],
        neighborOptions:[],
      }
    },
  
    components: {detail},
  
    computed: {},
  
    mounted() {
      this.searchInfo.data.EquipmentNum=this.$route.query.EquipmentNum ? this.$route.query.EquipmentNum :'';
      this.init();
      this.getNeighbor();
    },
  
    methods: {
      // 获取小区选项
      async getNeighbor(){
        await getNeibourhood({pageIndex:1,pageSize:100000,data:{}}).then(res=>{
          if(res.Success){
            this.neighborOptions=res.Data.data;
          }
        })
      },
      backToEquip(){
        this.$router.go(-1);
      },
      selectStatus(e){},
      // 初始化
      async init(){
        await getOrder(this.searchInfo).then(res=>{
          if(res.Success){
            this.orderInfo=res.Data.data;
            this.orderInfo.forEach(element => {
              element.BeginTime=this.timeToTime(element.BeginTime.substring(6,19));
              element.EndTime=element.Process == 2 && element.OrderState >= 2 ? this.timeToTime(element.EndTime.substring(6,19)) : '/';
              element.ActualAmount = element.OrderState>=2 ? (element.ActualAmount === 0 ? '未消费' : element.ActualAmount): (element.OrderState==0?'未消费':'待结算');
              element.ElectricityAmount =  element.OrderState >= 2 ? (element.ElectricityAmount===0 ? '未消费' : element.ElectricityAmount) : (element.OrderState==0?'未消费':'待结算');
              element.ServiceAmount =  element.OrderState >=2 ? (element.ServiceAmount===0 ? '未消费' : element.ServiceAmount) : (element.OrderState==0?'未消费':'待结算');
              element.OrderElectricNum =  element.OrderState >= 2 ? ( element.OrderElectricNum === 0 ? '未消费' : element.OrderElectricNum) :(element.OrderState==0?'未消费':element.OrderElectricNum);
              element.Process = element.Process == 0 ? '待支付' : (element.OrderState == 1 && element.Process == 0 ? '已支付' : (element.OrderState == 1 && element.Process == 1 ? '充电中' : '充电完成') );
              element.OrderState = element.OrderState==0 ? '待支付' : (element.OrderState== 1 ? '已支付' : (element.OrderState== 2 ? '订单结算' : (element.OrderState== 3 ? '退款中' : (element.OrderState == 4 ? '部分退款' :(element.OrderState == 5 ? '全额退款' : '--'))) ) );
              element.OrderAmount = element.OrderAmount == 0 ? '待结算' : element.OrderAmount;
            });
            this.total=res.Data.totalCount;
          }
        })
      },
      // 时间戳转时间
      timeToTime(time){
         const date = new Date(Number(time))
         const Y = date.getFullYear() // 年
         const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
         const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
         const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
         const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
         const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
         const Time=`${Y}-${M}-${D} ${H}:${m}:${S}`;
         return Time;
      },
      // 中国标准时间转年月日
      timeChange(time,ifend){
        var chinaStandard=time;
          var date = new Date(chinaStandard);
          var y = date.getFullYear();
          var m = date.getMonth() + 1;
          m = m < 10 ? ('0' + m) : m;
          var d = date.getDate();
          d = d < 10 ? ('0' + d) : d;
          var h = date.getHours();
          var minute = date.getMinutes();
          minute = minute < 10 ? ('0' + minute) : minute;
          var second = date.getSeconds();
          second = second < 10 ? ('0' + second) : second;
          let Time;
          if(ifend){
            Time = y + '/' + m + '/' + d+' 23:59:59';
          }else{
            Time = y + '/' + m + '/' + d+' '+h+':'+minute+ ':' + second;
          }
          return Time;
      },
      // 导出订单
      async educe(){
        await exportOrder(this.searchInfo.data).then(res=>{
          if(res.Success){
            window.open(baseUrl+'/'+res.Data);
          }else{
            this.$message({
              message: "查询不到该类数据",
              type: "fail",
            });
          }
        })
      },
      // 结束订单
      closeOrder(index,row){
        this.$confirm('确认结束该订单吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async ()=>{
          await CloseChargingOrder({OrderNum:row.OrderNum}).then(res=>{
            if(res.Success){
              this.$message({
                message: "订单已结束",
                type: "success",
              });
              this.init();
            }else{
              this.$message({
                message: "结单失败，请重试",
                type: "fail",
              });
              this.init();
            }
          })
        })
      },
      // 查看详情
      checkDetail(orderNum){
        this.row={orderNum:orderNum};
        this.detailDrawer=true;
      },
      // 新增、绑定、编辑成功
      handleDrawerSuccess(){
        this.detailDrawer=false;
      },
      // 关闭
      handleClose(){
        this.detailDrawer=false;
        this.init();
      },
      // 时间选择
      chooseTime(e){
        this.searchInfo.data.SBeginTime= this.timeChange(e[0]);
        this.searchInfo.data.SEndTime= this.timeChange(e[1]);
      },
      // 条件查询
      toSearch(){
        this.searchInfo.data.OrderState=this.state !==''  ? this.state : -1; 
        this.searchInfo.data.Process=this.process !==''  ? this.process : -1;
        this.searchInfo.data.NeighbourhoodId=this.neighbourhoodId !==''  ? this.neighbourhoodId : -1;
        this.searchInfo.pageIndex=1;
        this.init();
      },
      // 清除查询条件
      backAll(){
        this.searchInfo.pageIndex=1;
        this.searchInfo.data={
          Process:-1,
          OrderState:-1,
        };
        this.time='';
        this.state='';
        this.process='';
        this.neighbourhoodId = '';
        this.init();
      },
      // 分页
      handleCurrentChange(val){
        this.searchInfo.pageIndex=val;
        this.init();
      },
      prevpage(e){
        this.searchInfo.pageIndex=e;
        this.init();
      },
      nextpage(e){
        this.searchInfo.pageIndex=e;
        this.init();
      },
      // 退出登录
      logOut(){
        this.$emit('logOut');
      },
    },
  };
  </script>
  <style scoped lang='scss'>
  .el-icon-switch-button{
    font-size: 20px;
  }
  .orderContainer{
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .container{
    flex: 1;
  }
   .search{
      .inputs{
        display: flex;
        flex-wrap: wrap;
      }
      .wxName,.select,.timeSelect{
        width: 20%;
        margin:0 15px 15px 0;
      }
      .el-button{
        height: calc(100% - 15px);
      }
    }
    .search .wxName[data-v-0dade7ca], .search .select[data-v-0dade7ca], .search .timeSelect[data-v-0dade7ca]{
      margin-right: 5px;
    }
    
  // 按钮
    .btns{
      width: 100%;
      background-color: white;
      height: 60px;
      width: 100%;
      padding: 10px 50px;
      position: relative;
      button{
        position: absolute;
        right:20px;
        top:10px;
      }
    }
    
  
  .el-table >>> .cell {
    white-space: pre-line;
  }
  .el-drawer.rtl{
    overflow-y: auto !important;
  }
  
  .equipName{
    margin-bottom: 10px;
  }
  .equipNumber{
    color: #40a9ff;
  }
  .number{
    display: inline-block;
    height: 20px;
    width: 20px;
    padding: 0 2px;
    border: solid 1px #91d5ff;
    color:#40a9ff;
    background: #e6f7ff;
    border-radius: 3px;
    margin-left: 10px;
    text-align: center;
    font-size: 14px;
  }
  .el-tabs__content{
    overflow:auto !important;
  }
  .orderStatus{
    background:#40a9ff;
    color:#fff;
    display: inline-block;
    padding: 3px 5px;
    border-radius: 5px;
  }
  
  .stopWay{
    color: #40a9ff;
    margin-top: 10px;
  }
  </style>
  