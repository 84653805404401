import axios, {ResponseData} from './base.ts';
import {AxiosPromise} from 'axios';

// 登录
export function login(data = {}) {
  return axios.request({
    url: `Login/FnLogin`,
    method: "post",
    data: data,
  })
}
// 判断token是否失效
export function token(data = {}) {
  return axios.request({
    url: `Login/TokenNull`,
    method: "get",
    params: data,
  })
}
// 修改密码
export function modefyPassword(data = {}) {
  return axios.request({
    url: `Main/ModifyUserPassword`,
    method: "post",
    data: data,
  })
}

// 查询菜单列表
export function selectUserMenu(data = {}) {
  return axios.request({
    url: `Main/SelectUserMenu`,
    method: "post",
    data: data,
  })
}

// 查询菜单等级为1的菜单列表
export function getMenu(data={}){
  return axios.request({
    url:`MenuInfo/Getmenulevel1`,
    method:'post',
    data:data,
  })
}

// 菜单列表查询不做分页
export function getMenuList(data={}){
  return axios.request({
    url:`MenuInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 新增菜单
export function addMenu(data={}){
  return axios.request({
    url:`MenuInfo/Insert`,
    method:'post',
    data:data,
  })
}

// 修改菜单
export function editMenu(data={}){
  return axios.request({
    url:`MenuInfo/Update`,
    method:'post',
    data:data,
  })
}

// 删除菜单
export function deleteMenu(data={}){
  return axios.request({
    url:`MenuInfo/Delete`,
    method:'post',
    data:data,
  })
}

// 冻结菜单
export function lockMenu(data={}){
  return axios.request({
    url:`MenuInfo/Locked`,
    method:'post',
    data:data,
  })
}

// 用户查询列表
export function getUser(data={}){
  return axios.request({
    url:`UserInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 新增用户
export function addUser(data={}){
  return axios.request({
    url:`UserInfo/Insert`,
    method:'post',
    data:data,
  })
}

// 修改用户
export function editUser(data={}){
  return axios.request({
    url:`UserInfo/Update`,
    method:'post',
    data:data,
  })
}

// 删除用户
export function deleteUser(data={}){
  return axios.request({
    url:`UserInfo/Delete`,
    method:'post',
    data:data,
  })
}

// 冻结用户
export function lockUser(data={}){
  return axios.request({
    url:`UserInfo/Locked`,
    method:'post',
    data:data,
  })
}

// 角色查询
export function getRole(data={}){
  return axios.request({
    url:`RoleInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 新增角色
export function addRole(data={}){
  return axios.request({
    url:`RoleInfo/Insert`,
    method:'post',
    data:data,
  })
}

// 删除角色
export function deleteRole(data={}){
  return axios.request({
    url:`RoleInfo/Delete`,
    method:'post',
    data:data,
  })
}

// 获取角色列表
export function getRolesList(data={}){
  return axios.request({
    url:`Power/QueryManagerRoles`,
    method:'post',
    data:data,
  })
}

// 获取当前角色权限
export function getRolePower(data={}){
  return axios.request({
    url:`Power/QueryPower`,
    method:'post',
    data:data,
  })
}

// 修改当前角色权限
export function editRolePower(data={}){
  return axios.request({
    url:`Power/SetMenu`,
    method:'post',
    data:data,
  })
}

// 消费模板列表查询
export function getConsumeTemplateInfo(data={}){
  return axios.request({
    url:`ConsumeTemplateInfo/GetData`,
    method:'post',
    data:data,
    async: false,
  })
}
// 根据小区id查询消费模板
export function getDataToNId(data={}){
  return axios.request({
    url:`ConsumeTemplateInfo/GetDataToNId`,
    method:'post',
    data:data,
  })
}

// 新增消费模板
export function addConsumeTemplate(data={}){
  return axios.request({
    url:`ConsumeTemplateInfo/Insert`,
    method:'post',
    data:data,
    async: false,
  })
}

// 根据id查询对应模板详细信息
export function selectConsumeTemplate(data={}){
  return axios.request({
    url:`ConsumeTemplateInfo/Select`,
    method:'post',
    data:data,
    async: false,
  })
}

// 修改模板
export function updateConsumeTemplate(data={}){
  return axios.request({
    url:`ConsumeTemplateInfo/Update`,
    method:'post',
    data:data,
    async: false,
  })
}

// 删除模板
export function deleteConsumeTemplate(data={}){
  return axios.request({
    url:`ConsumeTemplateInfo/Delete`,
    method:'post',
    data:data,
    async: false,
  })
}

// 设备列表查询
export function getEquipment(data={}){
  return axios.request({
    url:`EquipmentInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 新增设备
export function addEquipment(data={}){
  return axios.request({
    url:`EquipmentInfo/Insert`,
    method:'post',
    data:data,
  })
}

// 设备列表生成二维码接口
export function createQRcode(data={}){
  return axios.request({
    url:`EquipmentInfo/CreateQRcode`,
    method:'post',
    data:data,
  })
}

//设备批量生成显屏二维码  
export function updateqrStr(data={}){
  return axios.request({
    url:`EquipmentInfo/UpdateqrStr`,
    method:'post',
    data:data,
  })
}

// 设备列表绑定小区接口
export function bandingNeighbourhood(data={}){
  return axios.request({
    url:`EquipmentInfo/BandingNeighbourhood`,
    method:'post',
    data:data,
  })
}

// 设备列表绑定小区接口
export function PlBandingNeighbourhood(data={}){
  return axios.request({
    url:`EquipmentInfo/PlBandingNeighbourhood`,
    method:'post',
    data:data,
  })
}

// 修改运营状态
export function updateOperate(data={}){
  return axios.request({
    url:`EquipmentInfo/UpdateOperate`,
    method:'post',
    data:data,
  })
}

// 查询设备明细
export function selectEquipment(data={}){
  return axios.request({
    url:`EquipmentInfo/Select`,
    method:'post',
    data:data,
  })
}

// 电表列表查询
export function getElectricMeter(data={}){
  return axios.request({
    url:`ElectricMeterInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 新增电表
export function addElectricMeter(data={}){
  return axios.request({
    url:`ElectricMeterInfo/Insert`,
    method:'post',
    data:data,
  })
}

// 查询详情电表
export function selectElectricMeter(data={}){
  return axios.request({
    url:`ElectricMeterInfo/Select`,
    method:'post',
    data:data,
  })
}

// 修改电表
export function updateElectricMeter(data={}){
  return axios.request({
    url:`ElectricMeterInfo/Update`,
    method:'post',
    data:data,
  })
}

// 订单列表查询
export function getOrder(data={}){
  return axios.request({
    url:`OrderInfo/GetData`,
    method:'post',
    data:data,
  })
}
// 订单结束订单
export function CloseChargingOrder(data={}){
  return axios.request({
    url:`OrderInfo/CloseChargingOrder`,
    method:'post',
    data:data,
  })
}

// 两轮订单
export function getBatteryOrder(data={}){
  return axios.request({
    url:`BatteryOrderInfo/GetData`,
    method:'post',
    data:data,
  })
}
// 两轮订单导出
export function exportBatteryOrder(data={}){
  return axios.request({
    url:`BatteryOrderInfo/ExportOrder`,
    method:'post',
    data:data,
  })
}
// 两轮详情
export function selectBatteryOrder(data={}){
  return axios.request({
    url:`BatteryOrderInfo/Select`,
    method:'post',
    data:data,
  })
}
// 两轮订单获取心跳电压图表
export function getBatteryVoltageChart(data={}){
  return axios.request({
    url:`BatteryOrderInfo/GetVoltageChart`,
    method:'post',
    data:data,
  })
}
// 两轮订单获取心跳电流图表
export function getBatteryElectricCurrentChart(data={}){
  return axios.request({
    url:`BatteryOrderInfo/GetElectricCurrentChart`,
    method:'post',
    data:data,
  })
}
// 两轮订单获取心跳功率图表
export function getBatteryPowerChart(data={}){
  return axios.request({
    url:`BatteryOrderInfo/GetPowerChart`,
    method:'post',
    data:data,
  })
}
// 两轮订单获取分润
export function getBatteryBenefit(data={}){
  return axios.request({
    url:`BatteryOrderInfo/GetBenefit`,
    method:'post',
    data:data,
  })
}


// 提现账号列表查询
export function getWithdrawalNum(data={}){
  return axios.request({
    url:`WithdrawalNumInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 新增提现账号
export function addWithdrawalNum(data={}){
  return axios.request({
    url:`WithdrawalNumInfo/Insert`,
    method:'post',
    data:data,
  })
}

// 修改提现账号
export function updateWithdrawalNum(data={}){
  return axios.request({
    url:`WithdrawalNumInfo/Update`,
    method:'post',
    data:data,
  })
}

// 提现账号查询详情
export function selectWithdrawalNum(data={}){
  return axios.request({
    url:`WithdrawalNumInfo/Select`,
    method:'post',
    data:data,
  })
}

// 提现申请列表查询
export function getWithdrawalApply(data={}){
  return axios.request({
    url:`WithdrawalApplyInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 分润明细列表查询
export function getBenefit(data={}){
  return axios.request({
    url:`BenefitInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 微信用户列表查询
export function getWechatUser(data={}){
  return axios.request({
    url:`WeChatUserInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 微信用户修改角色
export function updateWechatUser(data={}){
  return axios.request({
    url:`WeChatUserInfo/UpdateRole`,
    method:'post',
    data:data,
  })
}

// 微信用户添加余额
export function addAmount(data={}){
  return axios.request({
    url:`WeChatUserInfo/AddAmount`,
    method:'post',
    data:data,
  })
}

// 微信用户条件查询
export function selectWechatUser(data={}){
  return axios.request({
    url:`WeChatUserInfo/Select`,
    method:'post',
    data:data,
  })
}

// 储蓄卡列表查询
export function getStoredValueCard(data={}){
  return axios.request({
    url:`StoredValueCardInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 新增储蓄卡
export function addStoredValueCard(data={}){
  return axios.request({
    url:`StoredValueCardInfo/Insert`,
    method:'post',
    data:data,
  })
}
// 修改储蓄卡
export function editStoredValueCard(data={}){
  return axios.request({
    url:`StoredValueCardInfo/Update`,
    method:'post',
    data:data,
  })
}
// 查询小区列表
export function getNidList(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/Selectlist`,
    method:'post',
    data:data,
  })
}

// 储蓄卡绑定微信用户
export function storedValueCardBindWechat(data={}){
  return axios.request({
    url:`StoredValueCardInfo/BindWeChat`,
    method:'post',
    data:data,
  })
}

// 储蓄卡充值
export function rechargeStoredValueCard(data={}){
  return axios.request({
    url:`StoredValueCardInfo/RechargeCrad`,
    method:'post',
    data:data,
  })
}

// 管理小区列表查询
export function getNeibourhood(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 新增管理小区
export function addNeibourhood(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/Insert`,
    method:'post',
    data:data,
  })
}

// 管理小区 查询详情电表
export function selectNeibourhood(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/Select`,
    method:'post',
    data:data,
  })
}

// 管理小区 修改
export function updateNeibourhood(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/Update`,
    method:'post',
    data:data,
  })
}

// 管理小区 删除
export function deleteNeibourhood(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/Delete`,
    method:'post',
    data:data,
  })
}

// 管理小区 绑定物业
export function bandingProperty(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/BandingProperty`,
    method:'post',
    data:data,
  })
}

// 管理小区 绑定代理
export function bandingAgent(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/BandingAgent`,
    method:'post',
    data:data,
  })
}

// 首页查询数据
export function getSumInfo(data={}){
  return axios.request({
    url:`Index/GetSumInfo`,
    method:'post',
    data:data,
  })
}

// 首页查询收入图表数据
export function incomeChart(data={}){
  return axios.request({
    url:`Index/IncomeChart`,
    method:'post',
    data:data,
  })
}

// 首页查询订单图表数据
export function OrderChart(data={}){
  return axios.request({
    url:`Index/OrderChart`,
    method:'post',
    data:data,
  })
}

// 首页查询插座图表数据
export function portChart(data={}){
  return axios.request({
    url:`Index/PortChart`,
    method:'post',
    data:data,
  })
}

// 首页查询设备图表数据
export function EquipmentChart(data={}){
  return axios.request({
    url:`Index/EquipmentChart`,
    method:'post',
    data:data,
  })
}

// 微信用户根据角色查询对应列表信息
export function selectRole(data={}){
  return axios.request({
    url:`WeChatUserInfo/Select`,
    method:'post',
    data:data,
  })
}

// 小区列表设置分润规则
export function setBenefitRules(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/SetBenefitRules`,
    method:'post',
    data:data,
  })
}

// 小区列表设置消费模板
export function setConsumeTemplate(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/SetConsumeTemplate`,
    method:'post',
    data:data,
  })
}

// 查询模板列表
export function getTemplateList(data={}){
  return axios.request({
    url:`ConsumeTemplateInfo/GetList`,
    method:'post',
    data:data,
  })
}

// 订单导出
export function exportOrder(data={}){
  return axios.request({
    url:`OrderInfo/ExportOrder`,
    method:'post',
    data:data,
  })
}

// 订单查看详情
export function selectOrder(data={}){
  return axios.request({
    url:`OrderInfo/Select`,
    method:'post',
    data:data,
  })
}

// 订单查看心跳功率图表详情
export function getPowerChart(data={}){
  return axios.request({
    url:`OrderInfo/GetPowerChart`,
    method:'post',
    data:data,
  })
}

// 订单查看心跳电压图表详情
export function getVoltageChart(data={}){
  return axios.request({
    url:`OrderInfo/GetVoltageChart`,
    method:'post',
    data:data,
  })
}

// 订单查看心跳电流图表详情
export function getElectricCurrentChart(data={}){
  return axios.request({
    url:`OrderInfo/GetElectricCurrentChart`,
    method:'post',
    data:data,
  })
}

// 订单查看分润列表
export function getOrderBenefit(data={}){
  return axios.request({
    url:`OrderInfo/GetBenefit`,
    method:'post',
    data:data,
  })
}

// 分润导出
export function exportBenefit(data={}){
  return axios.request({
    url:`BenefitInfo/ExportBenefit`,
    method:'post',
    data:data,
  })
}

// 修改提现状态
export function updateApplyState(data={}){
  return axios.request({
    url:`WithdrawalApplyInfo/UpdateApplyState`,
    method:'post',
    data:data,
  })
}

// 获取省市区
export function getAddressList(data={}){
  return axios.request({
    url:`BCCPWebApi/GetAddressList`,
    method:'post',
    data:data,
  })
}

// 分润报表查询列表
export function getBenefitReport(data={}){
  return axios.request({
    url:`BenefitInfo/BenefitReport`,
    method:'post',
    data:data,
  })
}

// 分润报表导出
export function benefitReportExport(data={}){
  return axios.request({
    url:`BenefitInfo/BenefitReportExport`,
    method:'post',
    data:data,
  })
}

// 提现报表查询列表
export function getWithdrawalApplyReport(data={}){
  return axios.request({
    url:`WithdrawalApplyInfo/WithdrawalApplyReport`,
    method:'post',
    data:data,
  })
}

// 提现报表导出
export function withdrawalApplyReportExport(data={}){
  return axios.request({
    url:`WithdrawalApplyInfo/WithdrawalApplyReportExport`,
    method:'post',
    data:data,
  })
}

// 场地经营报表列表查询
export function GetOrderYueReports(data={}){
  return axios.request({
    url:`OrderInfo/GetOrderYueReports`,
    method:'post',
    data:data,
  })
}
// 场地经营报表导出
export function ExportOrderYueReports(data={}){
  return axios.request({
    url:`OrderInfo/ExportOrderYueReports`,
    method:'post',
    data:data,
  })
}

// 场地成本回收报表列表查询
export function GetOrderCostReport(data={}){
  return axios.request({
    url:`OrderInfo/GetOrderCostReport`,
    method:'post',
    data:data,
  })
}
// 场地成本回收报表导出
export function ExportOrderCostReport(data={}){
  return axios.request({
    url:`OrderInfo/ExportOrderCostReport`,
    method:'post',
    data:data,
  })
}

// 订单日结算记录报表列表查询
export function GetOrderDaySettlement(data={}){
  return axios.request({
    url:`OrderInfo/GetOrderDaySettlement`,
    method:'post',
    data:data,
  })
}
// 订单日结算记录报表导出
export function ExportOrderDaySettlements(data={}){
  return axios.request({
    url:`OrderInfo/ExportOrderDaySettlements`,
    method:'post',
    data:data,
  })
}

// 场地电瓶车经营报表列表查询
export function GetBatteryCarOrderYueReport(data={}){
  return axios.request({
    url:`BatteryOrderInfo/GetBatteryCarOrderYueReport`,
    method:'post',
    data:data,
  })
}
// 场地电瓶车经营报表导出
export function ExportBatteryCarOrderYueReports(data={}){
  return axios.request({
    url:`BatteryOrderInfo/ExportBatteryCarOrderYueReports`,
    method:'post',
    data:data,
  })
}



// 查询小区详细信息订单统计
export function getShowDataXiaoQu(data={}){
  return axios.request({
    url:`OrderInfo/GetShowDataXiaoQu`,
    method:'post',
    data:data,
  })
}

// 微信用户充值列表查询
export function getStoredValueInfo(data={}){
  return axios.request({
    url:`StoredValueInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 微信用户充值列表导出
export function exportStoredValue(data={}){
  return axios.request({
    url:`StoredValueInfo/ExportStoredValue`,
    method:'post',
    data:data,
  })
}

// 微信用户充值列表导出
export function getBenefitRulesDatas(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/GetBenefitRulesDatas`,
    method:'post',
    data:data,
  })
}

// 报文列表查询
export function getMessageInfo(data={}){
  return axios.request({
    url:`MessageInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 设备耗电统计 
export function getCumulativeElectricityInfo(data={}){
  return axios.request({
    url:`CumulativeElectricityInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 结算耗电
export function settlementElectricity(data={}){
  return axios.request({
    url:`CumulativeElectricityInfo/SettlementElectricity`,
    method:'post',
    data:data,
  })
}

// 结算耗电记录
export function agentElectricitySettlementInfo(data={}){
  return axios.request({
    url:`ElectricitySettlementInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 获取客服问答列表
export function getCustomerMessageInfo(data={}){
  return axios.request({
    url:`CustomerMessageInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 新增客服问答列表
export function insertCustomerMessageInfo(data={}){
  return axios.request({
    url:`CustomerMessageInfo/Insert`,
    method:'post',
    data:data,
  })
}

// 删除客服问答列表
export function delCustomerMessageInfo(data={}){
  return axios.request({
    url:`CustomerMessageInfo/Detele`,
    method:'post',
    data:data,
  })
}

// 修改客服问答列表
export function upDateCustomerMessageInfo(data={}){
  return axios.request({
    url:`CustomerMessageInfo/Update`,
    method:'post',
    data:data,
  })
}

// 查询客服问答
export function selectCustomerMessageInfo(data={}){
  return axios.request({
    url:`CustomerMessageInfo/Select`,
    method:'post',
    data:data,
  })
}

// 用户分润提现报表查询
export function getDataBenefit(data={}){
  return axios.request({
    url:`WeChatUserInfo/GetDataBenefit`,
    method:'post',
    data:data,
  })
}
// 用户分润提现报表导出
export function exportWeChatUserBenefit(data={}){
  return axios.request({
    url:`WeChatUserInfo/ExportBenefit`,
    method:'post',
    data:data,
  })
}


// 订单统计报表查询
export function getDataBenefitbyday(data={}){
  return axios.request({
    url:`OrderInfo/GetDataBenefitbyday`,
    method:'post',
    data:data,
  })
}

// 订单统计报表导出
export function exportBenefitbyday(data={}){
  return axios.request({
    url:`OrderInfo/ExportBenefitbyday`,
    method:'post',
    data:data,
  })
}

// 设备测试通信
export function GetEquipmentOnlink(data={}){
  return axios.request({
    url:`EquipmentInfo/GetEquipmentOnlink`,
    method:'post',
    data:data,
  })
}

// 设备转网操作
export function GetEquipmentSetNetwork(data={}){
  return axios.request({
    url:`EquipmentInfo/GetEquipmentSetNetwork`,
    method:'post',
    data:data,
  })
}

// 查询设备站点
export function GetEquipmentSite(data={}){
  return axios.request({
    url:`EquipmentInfo/GetEquipmentSite`,
    method:'post',
    data:data,
  })
}

// 获取保修上报数据 
export function getFaultReportInfo(data={}){
  return axios.request({
    url:`FaultReportInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 场所设置消费模板
export function SetConsumeTemplate(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/SetConsumeTemplate`,
    method:'post',
    data:data,
  })
}

// 获取汽车消费模板列表
export function GetConsumeTemplateInfo(data={}){
  return axios.request({
    url:`ConsumeTemplateInfo/GetList`,
    method:'post',
    data:data,
  })
}
// 获取电动车消费模板列表
export function getConsumeTemplateBatteryCarInfo(data={}){
  return axios.request({
    url:`ConsumeTemplateBatteryCarInfo/GetList`,
    method:'post',
    data:data,
  })
}
// 场所设置电动车分润
export function SetBatteryBenefitRules(data={}){
  return axios.request({
    url:`NeighbourhoodInfo/SetBatteryBenefitRules`,
    method:'post',
    data:data,
  })
}

// 电动车消费模板
// 消费模板列表查询
export function getBatteryConsumeTemplateList(data={}){
  return axios.request({
    url:`ConsumeTemplateBatteryCarInfo/GetData`,
    method:'post',
    data:data,
    async: false,
  })
}

// 新增模板
export function addBatteryConsumeTemplate(data={}){
  return axios.request({
    url:`ConsumeTemplateBatteryCarInfo/Insert`,
    method:'post',
    data:data,
    async: false,
  })
}

// 根据id查询对应模板详细信息
export function selectBatteryConsumeTemplate(data={}){
  return axios.request({
    url:`ConsumeTemplateBatteryCarInfo/Select`,
    method:'post',
    data:data,
    async: false,
  })
}

// 修改模板
export function updateBatteryConsumeTemplate(data={}){
  return axios.request({
    url:`ConsumeTemplateBatteryCarInfo/Update`,
    method:'post',
    data:data,
    async: false,
  })
}

// 删除模板
export function deleteBatteryConsumeTemplate(data={}){
  return axios.request({
    url:`ConsumeTemplateBatteryCarInfo/Delete`,
    method:'post',
    data:data,
    async: false,
  })
}

// 二轮电动车设备
// 设备列表查询
export function getEquipmentInfoBatteryCar(data={}){
  return axios.request({
    url:`EquipmentInfoBatteryCar/GetData`,
    method:'post',
    data:data,
  })
}

// 新增设备
export function addEquipmentInfoBatteryCar(data={}){
  return axios.request({
    url:`EquipmentInfoBatteryCar/Insert`,
    method:'post',
    data:data,
  })
}

// 设备列表生成二维码接口
export function createBatteryQRcode(data={}){
  return axios.request({
    url:`EquipmentInfoBatteryCar/CreateQRcode`,
    method:'post',
    data:data,
  })
}

// 设备列表绑定小区接口
export function bandingNeighbourhoodBatteryCar(data={}){
  return axios.request({
    url:`EquipmentInfoBatteryCar/BandingNeighbourhood`,
    method:'post',
    data:data,
  })
}

// 设备列表批量绑定小区接口
export function PlBandingNeighbourhoodBatteryCar(data={}){
  return axios.request({
    url:`EquipmentInfoBatteryCar/PlBandingNeighbourhood`,
    method:'post',
    data:data,
  })
}

// 修改运营状态
export function updateBatteryOperate(data={}){
  return axios.request({
    url:`EquipmentInfoBatteryCar/UpdateOperate`,
    method:'post',
    data:data,
  })
}

// 查询设备明细
export function selectEquipmentInfoBatteryCar(data={}){
  return axios.request({
    url:`EquipmentInfoBatteryCar/Select`,
    method:'post',
    data:data,
  })
}


// 查询开票列表
export function getWeChatUserToTalBill(data={}){
  return axios.request({
    url:`WeChatUserToTalBill/GetData`,
    method:'post',
    data:data,
  })
}
// 确认金额
export function updataWeChatUserToTalBill(data={}){
  return axios.request({
    url:`WeChatUserToTalBill/Updata`,
    method:'post',
    data:data,
  })
}
// 作废
export function upStateWeChatUserToTalBill(data={}){
  return axios.request({
    url:`WeChatUserToTalBill/UpState`,
    method:'post',
    data:data,
  })
}
// 开票信息下的订单信息
export function orderlistWeChatUserToTalBill(data={}){
  return axios.request({
    url:`WeChatUserToTalBill/Orderlist`,
    method:'post',
    data:data,
  })
}

// 车位锁管理列表
// export function getEquipmentFloorLockInfo(data={}){
//   return axios.request({
//     url:`EquipmentFloorLockInfo/GetData`,
//     method:'post',
//     data:data,
//   })
// }
export function getEquipmentFloorLockInfo(data={}){
  return axios.request({
    url:`FloorLockInfo/GetData`,
    method:'post',
    data:data,
  })
}
// 解锁、锁定地锁
export function FloorLockUpdate(data={}){
  return axios.request({
    url:`FloorLockInfo/FloorLockUpdate`,
    method:'post',
    data:data,
  })
}

// 新增车位锁管理
export function addEquipmentFloorLockInfo(data={}){
  return axios.request({
    url:`EquipmentFloorLockInfo/Insert`,
    method:'post',
    data:data,
  })
}

// 编辑运营状态
export function editEquipmentFloorLockInfo(data={}){
  return axios.request({
    url:`EquipmentFloorLockInfo/UpdateIsOperate`,
    method:'post',
    data:data,
  })
}

// 下发升降指令
export function updateLockEquipmentFloorLock(data={}){
  return axios.request({
    url:`EquipmentFloorLockInfo/UpdateLock`,
    method:'post',
    data:data,
  })
}

// 车位锁用户个人信息申请审核列表
export function getWeChatOwerInfo(data={}){
  return axios.request({
    url:`WeChatOwerInfo/GetData`,
    method:'post',
    data:data,
  })
}

// 车位锁申请审核 - 后台工作人员
export function auditUpWeChatOwerInfo(data={}){
  return axios.request({
    url:`WeChatOwerInfo/auditUp`,
    method:'post',
    data:data,
  })
}

// 删除车位锁用户信息 - 后台工作人员
export function deleteWeChatOwerInfo(data={}){
  return axios.request({
    url:`WeChatOwerInfo/Delete`,
    method:'post',
    data:data,
  })
}

// 修改车位锁用户信息 - 后台工作人员
export function updateWeChatOwerInfo(data={}){
  return axios.request({
    url:`WeChatOwerInfo/Update`,
    method:'post',
    data:data,
  })
}

// 车位锁预约列表查询 - 后台工作人员
export function getReservationInfo(data={}){
  return axios.request({
    url:`ReservationInfo/GetData`,
    method:'post',
    data:data,
  })
}
// 车位锁预约详情
export function selectReservationDetail(data={}){
  return axios.request({
    url:`ReservationInfo/selectInfo`,
    method:'post',
    data:data,
  })
}

// 数据字典
// 列表查询
export function getWechatDictionaries(data={}){
  return axios.request({
    url:`WechatDictionaries/GetData`,
    method:'post',
    data:data,
  })
}
// 新增
export function addWechatDictionaries(data={}){
  return axios.request({
    url:`WechatDictionaries/Insert`,
    method:'post',
    data:data,
  })
}
// 编辑
export function editWechatDictionaries(data={}){
  return axios.request({
    url:`WechatDictionaries/Update`,
    method:'post',
    data:data,
  })
}
// 删除
export function delWechatDictionaries(data={}){
  return axios.request({
    url:`WechatDictionaries/Delete`,
    method:'post',
    data:data,
  })
}
// 根据条件查询对应列表
export function getWechatDictionariesList(data={}){
  return axios.request({
    url:`WechatDictionaries/GetList`,
    method:'post',
    data:data,
  })
}


