<template>
  <section style="height: 100%;">
  <el-container id="manage-container" v-if="isLogin && flagPc" >
    <transition name="el-fade-in">
      <el-aside class="nav-container" v-show="true" width="auto">
        <div class="nav-top">
          <div style="color:white;font-size: 20px;font-weight: bolder">汽车慢充</div>
        </div>
        <el-scrollbar class="scroll-bar">
          <menu-container :active_name="active_name" @active="handleMenuActive"></menu-container>
        </el-scrollbar>
      </el-aside>
    </transition>
    <el-container>
      <el-header class="header-container">
        <head-container :show="true" :title="title" :crumb="crumb" @active="handleHeadCrumbActive" @logOut='logOut'></head-container>
      </el-header>
      <el-main class="main-container">
        <div id="app">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
  <PC v-if="isLogin && !flagPc" @logOut='logOut'></PC>
  <Login v-if="!isLogin" @goHome='goHome'></Login>
  </section>
</template>

<script>
  // @ is an alias to /src
  import MenuContainer from '@/components/MenuContainer';
  import HeadContainer from '@/components/HeadContainer';
  import Login from '@/views/Login.vue';
  import PC from '@/views/phone/index.vue';

  export default {
    name: 'App',
    components: {
      MenuContainer, HeadContainer,Login,PC
    },
    data() {
      return {
        title: '',
        crumb: [],
        active_name: '',
        isLogin:false,
        flagPc:true,
      }
    },
    created(){
      // 判断pc端还是移动端
      const IsPC=()=> {
        let userAgentInfo = navigator.userAgent;
        let Agents = [
          'Android',
          'iPhone',
          'SymbianOS',
          'Windows Phone',
          'iPad',
          'iPod'
        ];
        this.flagPc = true;
        for (let i = 0; i < Agents.length; i++) {
          if (userAgentInfo.indexOf(Agents[i]) > 0) {
            this.flagPc = false;
            break;
          }
        }
      }
      IsPC();
      console.log(this.flagPc,999)
    },
    methods: {
      handleMenuActive(e) {
        e.crumb.shift()
        this.active_name = e.name
        this.title = e.title
        this.crumb = e.crumb
      },
      handleHeadCrumbActive(e) {
        this.active_name = e
      },
      goHome(isLogin){
        this.isLogin = isLogin;
      },
      // 退出登录
      logOut(){
         this.$confirm('确认要退出登陆吗？')
         .then(()=>{
           sessionStorage.removeItem('userAccount');
           sessionStorage.removeItem('token');
           this.isLogin=false;
           sessionStorage.removeItem('login');
         })
         .catch(()=>{})
      },
      storeage(type){
        if(type===1){
          window.addEventListener("beforeunload", () => {
            sessionStorage.setItem('login',this.isLogin)
          });
        }else{
          window.removeEventListener("beforeunload", () => {});
        }
      }
    },
    mounted(){
      this.$bus.$on('isLogin',(flag)=>{
         if(!flag){
           this.isLogin=flag;
         }
      });
      const res = sessionStorage.getItem('login')?JSON.parse(sessionStorage.getItem('login')):false;
      if(res){
        this.isLogin=res;
        sessionStorage.setItem('login','');
        this.$forceUpdate()
      }
      this.storeage(1);//刷新前保持islogin的状态
    },
    //beforeDestroybeforeDestroy
    destroyed(){
      sessionStorage.setItem('login',this.isLogin);
      this.storeage(2)//组件销毁前移除监听事件
       this.$bus.$off('isLogin')
    }
  }
</script>

<style lang="scss">
  @import "./static/default";

  ::-webkit-scrollbar {/* 滚动条整体样式 */
      height: 8px; /* 宽高分别对应横竖滚动条的尺寸 */
      width: 8px;
      background:#fff;
      border-radius: 2px;
      // -webkit-box-shadow: inset 1px 0 1px rgba(0,0,0,0.2);
  }
  ::-webkit-scrollbar-thumb {/* 滚动条里的小方块 */
      border-radius: 4px;
      background: #c7c9cc;
      height: 120px;
  }
  ::-webkit-scrollbar-track {/* 滚动条里面的轨道 */
      -webkit-box-shadow: inset 0 0 2px #fff;
      border-radius: 1px;
      background:#fff;
  }
body{
  height: 100vh;
}
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow:auto;
    height: 100%;
  }

  div {
    box-sizing: border-box;
  }

  #manage-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(240, 242, 245, 1);
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    overflow:auto;
  }

  .header-container {
    height: 82px !important;
    padding: 0 !important;
  }

  .main-container {
    width: 100%;
    height: 100%;
    padding: 24px !important;
    overflow:auto;
  }

  .nav-container {
    width: 208px !important;
    height: 100vh;
    overflow: hidden !important;
    background-color: $primary-color;
    box-shadow: 3px 0px 8px 0px rgba(0, 21, 41, 0.35);

    .nav-top {
      width: 100%;
      height: 23px;
      margin-bottom: 30px;
      margin-top: 29px;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-image {
        width: 60%;
      }
    }

    .scroll-bar {
      width: 100%;
      overflow-x: hidden;
      height: calc(100vh - 23px - 59px);
    }
  }

  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }
  .el-scrollbar{
    padding-bottom: 15px !important;
  }
  .el-drawer__body{
    overflow: auto;
  }

  // 分页组件样式
  .toolbar{
    background: #fff;
    padding: 10px;
  }
  
</style>
