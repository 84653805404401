














































import {Component, Prop, Vue, Emit, Watch} from 'vue-property-decorator';
//@ts-ignore
import { modefyPassword } from '@/api/public.js';


@Component
export default class HeadContainer extends Vue {
    @Prop({default: false}) private show!: boolean;
    @Prop({default: ''}) private title!: string;
    @Prop({default: []}) private crumb!: string;
    // @Prop({default: ''}) private userName!: string;
    private userName: string ='';
    private userRole:string;
    private mineTitle = this.title;
    private mineCrumb = this.crumb;
    private mineShow: boolean = this.show;
    private oldPass: string = '';
    private newPass: string = '';
    private checkNew: string = '';
    private showCover:boolean = false;

    @Watch('show', {immediate: true, deep: true})
    private onShowChange(newValue: boolean, oldValue: boolean) {
        this.mineShow = newValue
    }

    @Watch('title', {immediate: true, deep: true})
    private onTitleChange(newValue: string, oldValue: string) {
        this.mineTitle = newValue
        // this.$forceUpdate()
    }

    @Watch('crumb', {immediate: true, deep: true})
    private onCrumbChange(newValue: any, oldValue: any) {
        this.mineCrumb = newValue;
    }

    private handleCrumbClick(name: string) {
        // if (this.$router.currentRoute.name === name) {
        //     return false
        // }
        this.emitCrumbActive(name)
    }

    @Emit('active')
    private emitCrumbActive(name: string) {
        return name
    } 

    @Emit('logOut')
    private out(){

    }
    // 关闭冒泡
    private stop(e){
      window.event? window.event.cancelBubble = true : e.stopPropagation();
    }
    // 点击遮罩层关闭弹框
    private hideCover(){
      this.showCover=false;
    }
    // 确认修改密码
    private confirm(){
      let data={data1:this.oldPass,data2:this.newPass};
      if(this.newPass===this.checkNew){
        modefyPassword(data).then(res=>{
          if(res.Success){
            this.$message({
              showClose: true,
              message: res.Message,
              type: 'success'
            });
            this.newPass='';
            this.oldPass='';
            this.checkNew='';
            Vue.prototype.$bus.$emit('isLogin', false);
          }else{
            this.$message({
              showClose: true,
              message: res.Message,
              type: 'error'
            });
          }
        })
      }else{
        this.$message({
          showClose: true,
          message: '两次输入的密码不一致',
          type: 'error'
        });
      }
      
    }
    // 取消修改密码
    private cancel(){
      this.showCover=false;
    }
    // private logOut(){}
    public handleCommand(command:any):void{
      if(command =='logOut'){//退出登录
        this.out();
      }else if(command==='rePass'){//修改密码
        this.showCover=true;
      }
    }

    public mounted(): void {
      let user=JSON.parse(sessionStorage.getItem('user'));
      this.userName =sessionStorage.getItem('UserName')
      this.$forceUpdate()
      this.userRole = this.$store.state.user.userData.role_name
    }

}
